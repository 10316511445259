/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
sh-pane {
  background-color: white;
}




.mat-step-icon-selected{
  background-color: #CF4B00 !important;
}

.mat-step-header{
  padding-left: 0.5rem !important;
}

.mat-step-label{
  margin-top:-1rem !important;
  margin-left: -1.5rem !important;
}

.mat-vertical-content-container{
  margin-left: 20px !important;
}

sh-menu-item.hidden{
  display: none;
}